import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import axios from "axios";
import Chart from "chart.js/auto";

const ChartArb = () => {
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: "WYS Price",
        fill: false,
        lineTension: 0.2,
        backgroundColor: "yellow",
        borderColor: "yellow",
        borderWidth: 2,
        pointBorderColor: "yellow",
        pointBackgroundColor: "yellow",
        pointBorderWidth: 3,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "white",
        pointHoverBorderColor: "yellow",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        pointStyle: "circle",
        data: [],
      },
      {
        label: "ARB Price",
        fill: false,
        lineTension: 0.2,
        backgroundColor: "green",
        borderColor: "green",
        borderWidth: 2,
        pointBorderColor: "green",
        pointBackgroundColor: "green",
        pointBorderWidth: 3,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "white",
        pointHoverBorderColor: "green",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        pointStyle: "circle",
        data: [],
      },
    ],
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [range, setRange] = useState("7"); // Default to 7 days

  useEffect(() => {
    const fetchPrices = async () => {
      try {
        // Fetch WYS prices
        const wysResponse = await axios.get(
          "https://api.coingecko.com/api/v3/coins/wyscale/market_chart",
          {
            params: {
              vs_currency: "usd",
              days: range,
            },
          }
        );
        const wysPrices = wysResponse.data.prices;

        // Fetch ARB prices
        const arbResponse = await axios.get(
          "https://api.coingecko.com/api/v3/coins/arbitrum/market_chart",
          {
            params: {
              vs_currency: "usd",
              days: range,
            },
          }
        );
        const arbPrices = arbResponse.data.prices;

        // Transform data into chart format
        const labels = wysPrices.map((price) =>
          new Date(price[0]).toLocaleDateString()
        );
        const wysData = wysPrices.map((price) => price[1]);
        const arbData = arbPrices.map((price) => price[1]);

        setChartData({
          labels: labels.reverse(), // Reverse for chronological order
          datasets: [
            {
              label: "WYS Price",
              fill: false,
              lineTension: 0.2,
              backgroundColor: "yellow",
              borderColor: "yellow",
              borderWidth: 2,
              pointBorderColor: "yellow",
              pointBackgroundColor: "yellow",
              pointBorderWidth: 3,
              pointHoverRadius: 5,
              pointHoverBackgroundColor: "white",
              pointHoverBorderColor: "yellow",
              pointHoverBorderWidth: 2,
              pointRadius: 1,
              pointHitRadius: 10,
              pointStyle: "circle",
              data: wysData.reverse(), // Reverse for chronological order
            },
            {
              label: "ARB Price",
              fill: false,
              lineTension: 0.2,
              backgroundColor: "green",
              borderColor: "green",
              borderWidth: 2,
              pointBorderColor: "green",
              pointBackgroundColor: "green",
              pointBorderWidth: 3,
              pointHoverRadius: 5,
              pointHoverBackgroundColor: "white",
              pointHoverBorderColor: "green",
              pointHoverBorderWidth: 2,
              pointRadius: 1,
              pointHitRadius: 10,
              pointStyle: "circle",
              data: arbData.reverse(), // Reverse for chronological order
            },
          ],
        });
        setLoading(false);
      } catch (error) {
        console.error("Error fetching prices:", error);
        setError(error);
        setLoading(false);
      }
    };

    fetchPrices();
  }, [range]);

  const options = {
    maintainAspectRatio: false,
    scales: {
      x: {
        type: "category",
        border: {
          color: "#313131",
        },
        labels: chartData.labels,
        grid: {
          lineColor: "#ffcc33",
        },
      },
      y: {
        border: {
          color: "#313131",
        },
      },
    },
    elements: {
      point: {
        pointStyle: "circle",
      },
    },
  };

  if (loading) return <div className="text-white">Loading...</div>;
  if (error) return <div>""</div>;

  return (
    <div style={{ height: "auto", width: "100%" }}>
      <div
        className="row col-lg-12"
        style={{
          border: "1px solid",
          borderRadius: "12px",
          height: "fit-content",
          position: "relative",
          margin: "20px 0px",
        }}
      >
        <div className="row col-lg-4 tvl">
          <a href="#apy">APY</a>
          <a href="#tvl">TVL</a>
          <a href="#balance">My Balance</a>
        </div>
        <div
          className="row col-lg-4 y"
          style={{ position: "absolute", right: "5px" }}
        >
          <a
            href="javascript:void(0)"
            onClick={() => setRange("1")}
            style={{ color: `${range == 1 ? "#ead313" : "white"}` }}
          >
            1D
          </a>
          <a
            href="javascript:void(0)"
            onClick={() => setRange("7")}
            style={{ color: `${range == 7 ? "#ead313" : "white"}` }}
          >
            1W
          </a>
          <a
            href="javascript:void(0)"
            onClick={() => setRange("30")}
            style={{ color: `${range == 30 ? "#ead313" : "white"}` }}
          >
            1M
          </a>
          <a
            href="javascript:void(0)"
            onClick={() => setRange("180")}
            style={{ color: `${range == 180 ? "#ead313" : "white"}` }}
          >
            6M
          </a>
          <a
            href="javascript:void(0)"
            onClick={() => setRange("365")}
            style={{ color: `${range == 365 ? "#ead313" : "white"}` }}
          >
            1Y
          </a>
        </div>
        <div
          className="row col-lg-12"
          style={{
            margin: "20px 0px",
            padding: "5px",
            height: "300px",
          }}
        >
          <Line data={chartData} options={options} />
        </div>
      </div>
    </div>
  );
};

export default ChartArb;
